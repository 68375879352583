<template>
    <div>

        <RecordDialog v-if="showRecordDialog" v-bind="recordDialogParameters"
                      v-on:hidden="showRecordDialog = false"
                      v-on:ok="fetchData(data.id)"
                      v-on:success-create-order="successCreateOrder"
                      v-on:success-update-section="sectionUpdated"/>

        <!-- DOCUMENT HEADER -->

        <div class="card st-framework-card" v-if="pageLoaded">

            <div class="card-header">
                <table style="border-collapse: collapse; width: 100%;">
                    <tr>
                        <td style="margin: 0; padding: 0 0 0 1rem; width: 280px; border: solid 1px lightgray;">
                            <font-awesome-icon :icon="['fas', 'calendar']" size="sm" class="mr-2"/>
                            <template v-for="(action,actionIdx) in actions.manageActions">
                                <SelectAction :key="action.name+'-'+actionIdx"
                                              :action="action" :disabled="state.disabled"
                                              v-on:select-view="selectView"
                                              v-if="action.type === 'SELECT' && action.placement === 'DOCUMENTHEADERLEFT'"/>
                            </template>
                        </td>
                        <td style="width: 1rem;"></td>
                        <td style="margin: 0; padding: 0 10px; border: solid 1px lightgray;">
                            <SelectField :field="fields.selectField" v-on:input="documentSelectInput"/>
                        </td>
                        <td style="margin: 0; padding: 0; border: solid 1px lightgray; text-align: center; width: 38px;"
                            v-if="showCreateButton">
                            <template v-for="(action,actionIdx) in actions.manageActions">
                                <ButtonAction :key="action.name+'-'+actionIdx" :action="action" :disabled="state.disabled"
                                              v-on:create-document="createOrder"
                                              v-if="action.type === 'BUTTON' && action.placement === 'DOCUMENTHEADERRIGHT'"/>
                            </template>
                        </td>
                        <td style="margin: 0; padding: 0; width: 90px; text-align: right;">(#{{ data.id }})</td>
                        <td style="width: 1rem;"></td>
                    </tr>
                </table>
            </div>

            <div class="card-body p-0">
                <div class="row no-gutters">

                    <!-- DOCUMENT CARD -->

                    <div class="col-4">
                        <div class="card mb-0">
                            <div class="card-header">
                                <b-form inline>
                                    {{ $i18n.tc('translations.Order', 1) }} ({{ data.status_text }})
                                    <template v-for="(action,actionIdx) in actions.manageActions">
                                        <ButtonAction :key="action.name+'-'+actionIdx" :action="action" :disabled="state.disabled"
                                                      v-on:update-document="updateOrder"
                                                      v-if="action.type === 'BUTTON' && action.placement === 'DOCUMENTCARDHEADER'"/>
                                    </template>
                                </b-form>
                            </div>
                            <div class="card-body">
                                <table>
                                    <tr>
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Number') }}</b></td>
                                        <td class="p-0">{{ data.financial_year_number }}</td>
                                    </tr>
                                    <tr v-if="data.reference">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Reference') }}</b></td>
                                        <td class="p-0">{{ data.reference }}</td>
                                    </tr>
                                    <tr>
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Name') }}</b></td>
                                        <td class="p-0">{{ data.name }}</td>
                                    </tr>
                                    <tr v-if="data.date">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Date') }}</b></td>
                                        <td class="p-0">{{ data.date }}</td>
                                    </tr>
                                    <tr v-else>
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Valid') }}</b></td>
                                        <td class="p-0">{{ data.valid_from + ' ' + $i18n.t('translations.until') + ' ' + data.valid_to }}</td>
                                    </tr>
                                    <tr v-if="data.invoice_date">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Invoice Date') }}</b></td>
                                        <td class="p-0">{{ data.invoice_date }}</td>
                                    </tr>
                                    <tr v-if="data.invoice_number">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Invoice Number') }}</b></td>
                                        <td class="p-0">{{ data.invoice_number }}</td>
                                    </tr>
                                    <template v-for="(action,actionIdx) in actions.manageActions">
                                        <template v-if="action.placement === 'DOCUMENTCARDBODY' && action.type === 'LINK'">
                                            <tr :key="action.name+'-'+actionIdx">
                                                <td class="p-0" style="width: 120px;"><b>{{ action.label }}</b></td>
                                                <td class="p-0">
                                                    <LinkAction :action="action"></LinkAction>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </table>
                            </div>
                            <div class="card-footer light" v-if="showDocumentCardFooter">
                                <div class="row">
                                    <div class="col">
                                        <template v-for="(action,index) in actions.manageActions">
                                            <template v-if="action.placement === 'DOCUMENTCARDFOOTERLEFT'">
                                                <ToggleAction :key="action.name + '-' + index" :action="action" :disabled="state.disabled" :isOn="sketchUpToolData.order_id === data.id"
                                                              v-on:toggle-sketchup-order="toggleSketchUpOrder"
                                                              v-if="sketchUpToolData && action.name === 'toggle-sketchup-order'"/>
                                            </template>
                                        </template>
                                    </div>
                                    <div class="col-auto">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- PROJECT CARD -->

                    <div class="col-4">
                        <div class="card mb-0">
                            <div class="card-header">
                                <b-form inline>
                                    {{ $i18n.tc('translations.Project', 1) }} ({{ data.project_id.project_status_id.label }})
                                    <template v-if="!state.readonly">
                                        <template v-for="(action,actionIdx) in actions.manageActions">
                                            <ButtonAction :key="action.name+'-'+actionIdx" :action="action" :disabled="state.disabled"
                                                          v-on:update-project="updateProject"
                                                          v-if="action.type === 'BUTTON' && action.placement === 'PROJECTCARDHEADER'"/>
                                        </template>
                                    </template>
                                </b-form>
                            </div>
                            <div class="card-body">
                                <table>
                                    <tr>
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Number') }}</b></td>
                                        <td class="p-0">{{ data.project_id.id }}</td>
                                    </tr>
                                    <template v-for="(action,actionIdx) in actions.manageActions">
                                        <template v-if="action.placement === 'PROJECTCARDBODY' && action.type === 'LINK'">
                                            <tr :key="action.name+'-'+actionIdx">
                                                <td class="p-0" style="width: 120px;"><b>{{ action.label }}</b></td>
                                                <td class="p-0">
                                                    <LinkAction :action="action"></LinkAction>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                    <tr v-if="data.project_id.reference">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Reference') }}</b></td>
                                        <td class="p-0">{{ data.project_id.reference }}</td>
                                    </tr>
                                    <tr v-if="data.project_id.manager">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Manager') }}</b></td>
                                        <td class="p-0">{{ data.project_id.manager }}</td>
                                    </tr>
                                    <tr v-if="data.project_id.correspondent">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Correspondent') }}</b></td>
                                        <td class="p-0">{{ data.project_id.correspondent }}</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="card-footer light" v-if="showProjectCardFooter">
                                <div class="row">
                                    <div class="col">
                                        <template v-for="(action,index) in actions.manageActions">
                                            <template v-if="action.placement === 'PROJECTCARDFOOTERLEFT'">
                                                <ToggleAction :key="action.name + '-' + index" :action="action" :disabled="state.disabled" :isOn="sketchUpToolData.project_id === data.project_id.id"
                                                              v-on:toggle-sketchup-project="toggleSketchUpProject"
                                                              v-if="sketchUpToolData && action.name === 'toggle-sketchup-project'"/>
                                            </template>
                                        </template>
                                    </div>
                                    <div class="col-auto">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- CUSTOMER CARD -->

                    <div class="col-4">
                        <div class="card mb-0">
                            <div class="card-header">
                                <b-form inline>
                                    {{ $i18n.tc('translations.Customer', 1) }}
                                    <template v-if="!state.readonly">
                                        <template v-for="(action,actionIdx) in actions.manageActions">
                                            <ButtonAction :key="action.name+'-'+actionIdx" :action="action" :disabled="state.disabled"
                                                          v-on:update-customer="updateCustomer"
                                                          v-if="action.type === 'BUTTON' && action.placement === 'CUSTOMERCARDHEADER'"/>
                                        </template>
                                    </template>
                                </b-form>
                            </div>
                            <div class="card-body">
                                <table>
                                    <tr v-if="data.project_id.customer_id.full_name">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Name') }}</b></td>
                                        <td class="p-0">{{ data.project_id.customer_id.full_name }}</td>
                                    </tr>
                                    <tr v-if="data.project_id.customer_id.company">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Company') }}</b></td>
                                        <td class="p-0">{{ data.project_id.customer_id.company }}</td>
                                    </tr>
                                    <tr v-if="data.project_id.customer_id.street || data.project_id.customer_id.city || data.project_id.customer_id.country">
                                        <td class="p-0" style="width: 120px; vertical-align: top;"><b>{{ $i18n.t('translations.Address') }}</b></td>
                                        <td class="p-0">
                                            <span v-if="data.project_id.customer_id.street">{{ data.project_id.customer_id.street }} {{ data.project_id.customer_id.number }}<br></span>
                                            <span v-if="data.project_id.customer_id.city">{{ data.project_id.customer_id.postal }} {{ data.project_id.customer_id.city }}<br></span>
                                            <span v-if="data.project_id.customer_id.country">{{ data.project_id.customer_id.country }}</span>
                                        </td>
                                    </tr>
                                    <tr v-if="data.project_id.customer_id.vat">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.VAT') }}</b></td>
                                        <td class="p-0">{{ data.project_id.customer_id.vat }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- DOCUMENT SECTIONS -->

                <template v-for="(section,sectionIdx) in data.sections">
                    <div :key="'phase-'+sectionIdx+'-'+sectionReload[section.id]" class="card">
                        <div class="card-header phase-header" v-on:click="toggleSection(section)">
                            <table class="phase-header-table">
                                <tr>
                                    <td style="width: 15px; text-align: center;">
                                        <font-awesome-icon :icon="['fas', 'chevron-right']" size="sm" v-if="sectionCollapsed[section.id]"/>
                                        <font-awesome-icon :icon="['fas', 'chevron-down']" size="sm" v-else/>
                                    </td>
                                    <td style="width: 155px; text-align: center;">
                                        <template v-if="section.calculated_delivery_date_f">{{ section.calculated_delivery_date_f }}</template>
                                    </td>
                                    <td>{{ section.name }}</td>

                                    <!-- REMINDER -->

                                    <template v-if="section.work_plan_active && section.task_sent_to_customer && !section.task_approved">
                                        <template v-if="section.reminders.length > 0">
                                            <td style="width: 350px; background-color: #636f83; color: white; text-align: right; font-weight: normal; padding: 0 5px;">
                                                <a style="margin-right: 10px;">
                                                    <font-awesome-icon :icon="['fas', 'bell']" class="mr-2"/>
                                                    {{ $i18n.tc('translations.Reminder', 1) }}: {{ section.reminders[0].reminder_at_f }}
                                                </a>
                                                <template v-for="(action,actionIdx) in actions.reminderActions">
                                                    <ButtonAction :key="action.name+'-'+sectionIdx+'-'+actionIdx" :action="action"
                                                                  v-on:update-reminder="updateReminder(section)"
                                                                  v-if="action.name === 'update-reminder'"/>
                                                    <ButtonAction :key="action.name+'-'+sectionIdx+'-'+actionIdx" :action="action"
                                                                  v-on:delete-reminder="deleteReminder(section)"
                                                                  v-if="action.name === 'delete-reminder'"/>
                                                </template>
                                            </td>
                                        </template>
                                        <template v-else>
                                            <td style="text-align: right; padding: 0 5px;">
                                                <template v-for="(action,actionIdx) in actions.reminderActions">
                                                    <ButtonAction :key="action.name+'-'+sectionIdx+'-'+actionIdx" :action="action"
                                                                  v-on:create-reminder="createReminder(section)"
                                                                  v-if="action.name === 'create-reminder'"/>
                                                </template>
                                            </td>
                                        </template>
                                    </template>
                                </tr>
                            </table>
                        </div>
                        <div class="card-body phase-body st-fadein-content" v-if="!sectionCollapsed[section.id]">
                            <table>
                                <tr>
                                    <td rowspan="3" style="width: 450px; vertical-align: top; padding-left: 8px;">
                                        <table>

                                            <!-- work_plan_active -->

                                            <tr>
                                                <td colspan="2" style="padding-bottom: 1rem;">
                                                    <Field :field="fields.workPlanActiveField" :data="section" :state="state"
                                                           v-on:inline-edit="sectionUpdated"/>
                                                </td>
                                            </tr>

                                            <!-- expected_delivery_date -->

                                            <tr>
                                                <td style="width: 180px;">
                                                    {{ fields.expectedDeliveryDateField.label }}:
                                                </td>
                                                <td>
                                                    <Field :field="fields.expectedDeliveryDateField" :data="section" :readonly="!section.work_plan_active" :state="state"
                                                           v-on:inline-edit="sectionUpdated"/>
                                                </td>
                                            </tr>

                                            <!-- delivery_date -->

                                            <tr>
                                                <td>
                                                    {{ fields.deliveryDateField.label }}:
                                                </td>
                                                <td>
                                                    <Field :field="fields.deliveryDateField" :data="section" :readonly="!section.work_plan_active" :state="state"
                                                           v-on:inline-edit="sectionUpdated"/>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td style="padding-bottom: 1rem;">
                                        <div class="row">

                                            <!-- CONTACT -->

                                            <div class="col">
                                                <div class="card" style="margin: 0;">
                                                    <div class="card-header" style="padding: 5px;">
                                                        <table style="width: 100%;">
                                                            <tr>
                                                                <td>
                                                                    {{ $i18n.tc('translations.Contact', 1) }}
                                                                </td>
                                                                <td style="text-align: right;">
                                                                    <template v-for="(action,actionIdx) in actions.manageActions">
                                                                        <template v-if="action.placement === 'CONTACTCARDHEADERRIGHT' && section.work_plan_active">
                                                                            <ButtonAction :key="action.name+'-'+sectionIdx+'-'+actionIdx" :action="action"
                                                                                          v-on:create-contact="createContact(section)"
                                                                                          v-if="!section.contact_id && action.name === 'create-contact'"/>
                                                                            <ButtonAction :key="action.name+'-'+sectionIdx+'-'+actionIdx" :action="action"
                                                                                          v-on:update-contact="updateContact(section)"
                                                                                          v-if="section.contact_id && action.name === 'update-contact'"/>
                                                                            <ButtonAction :key="action.name+'-'+sectionIdx+'-'+actionIdx" :action="action"
                                                                                          v-on:delete-contact="deleteContact(section)"
                                                                                          v-if="section.contact_id && action.name === 'delete-contact'"/>
                                                                        </template>
                                                                    </template>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="card-body" style="padding: 10px;" v-if="section.contact_id">
                                                        <div v-if="section.contact_id.full_name">{{ section.contact_id.full_name }}</div>
                                                        <div v-if="section.contact_id.email">{{ section.contact_id.email }}</div>
                                                        <div v-if="section.contact_id.mobile">{{ section.contact_id.mobile }}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- DELIVERY ADDRESS -->

                                            <div class="col">
                                                <div class="card" style="margin: 0;">
                                                    <div class="card-header" style="padding: 5px;">
                                                        <table style="width: 100%;">
                                                            <tr>
                                                                <td>
                                                                    <template v-for="(action,actionIdx) in actions.manageActions">
                                                                        <template v-if="action.placement === 'RECIPIENTCARDHEADERLEFT'">
                                                                            <ButtonAction :key="action.name+'-'+sectionIdx+'-'+actionIdx" :action="action"
                                                                                          v-on:locate-recipient="locateRecipient(section)"
                                                                                          v-if="section.google_maps_url"/>
                                                                        </template>
                                                                    </template>
                                                                    {{ $i18n.tc('translations.Delivery Address', 1) }}
                                                                </td>
                                                                <td style="text-align: right;">
                                                                    <template v-for="(action,actionIdx) in actions.manageActions">
                                                                        <template v-if="action.placement === 'RECIPIENTCARDHEADERRIGHT' && section.work_plan_active">
                                                                            <ButtonAction :key="action.name+'-'+sectionIdx+'-'+actionIdx" :action="action"
                                                                                          v-on:create-recipient="createRecipient(section)"
                                                                                          v-if="!section.recipient_id && action.name === 'create-recipient'"/>
                                                                            <ButtonAction :key="action.name+'-'+sectionIdx+'-'+actionIdx" :action="action"
                                                                                          v-on:update-recipient="updateRecipient(section)"
                                                                                          v-if="section.recipient_id && action.name === 'update-recipient'"/>
                                                                            <ButtonAction :key="action.name+'-'+sectionIdx+'-'+actionIdx" :action="action"
                                                                                          v-on:delete-recipient="deleteRecipient(section)"
                                                                                          v-if="section.recipient_id && action.name === 'delete-recipient'"/>
                                                                        </template>
                                                                    </template>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="card-body" style="padding: 10px;" v-if="section.recipient_id">
                                                        <div v-if="section.recipient_id.street">{{ section.recipient_id.street }} {{ section.recipient_id.number }}</div>
                                                        <div v-if="section.recipient_id.city">{{ section.recipient_id.postal }} {{ section.recipient_id.city }}</div>
                                                        <div v-if="section.recipient_id.country">{{ section.recipient_id.country }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <!-- TASK STATUSSES -->

                                <tr>
                                    <td style="padding-bottom: 1rem; vertical-align: top">
                                        <div>
                                            <template v-for="taskStatusField in fields.taskStatusFields">
                                                <div :key="sectionIdx + taskStatusField.name" style="display: inline-block; width: 200px;">
                                                    <Field :field="taskStatusField" :data="section" :readonly="!section.work_plan_active" :state="state"
                                                           v-on:inline-edit="sectionUpdated"/>
                                                </div>
                                            </template>
                                        </div>
                                    </td>
                                </tr>

                                <!-- COMMENT -->

                                <tr>
                                    <td v-on:click="updateComment(section)">
                                        {{ fields.commentField.label }}
                                        <div class="st-border-light" :class="fields.commentField.class" :style="fields.commentField.style">
                                            <RichTextField :field="fields.commentField" :data="section" readonly hidetoolbar transparent/>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="card-footer light" v-if="!sectionCollapsed[section.id] && showElementFooter">
                            <div class="row">
                                <div class="col">
                                    <template v-for="(action,index) in actions.manageActions">
                                        <template v-if="action.placement === 'ELEMENTFOOTERLEFT'">
                                            <ToggleAction :key="action.name + '-' + index" :action="action" :disabled="state.disabled" :isOn="sketchUpToolData.phase_id === section.id"
                                                          v-on:toggle-sketchup-phase="toggleSketchUpPhase(section)"
                                                          v-if="sketchUpToolData && action.name === 'toggle-sketchup-phase'"/>
                                        </template>
                                    </template>
                                </div>
                                <div class="col-auto">
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

            </div>

        </div>

    </div>
</template>

<script>
import ButtonAction from "@/components/actions/ButtonAction.vue";
import Field from "@/components/fields/Field.vue";
import LinkAction from "@/components/actions/LinkAction";
import RecordDialog from "@/components/dialogs/RecordDialog.vue";
import SelectAction from "@/components/actions/SelectAction.vue";
import SelectField from "@/components/forms/fields/SelectField.vue";
import RichTextField from "@/components/forms/fields/RichTextField.vue";
import ToggleAction from "@/components/actions/ToggleAction.vue";

/* global sketchup:false */

export default {
    name: "WorkPlan",
    components: {
        ToggleAction,
        RichTextField,
        ButtonAction,
        Field,
        LinkAction,
        RecordDialog,
        SelectAction,
        SelectField,
    },
    props: ['state', 'userLanguage', 'languages'],
    data() {
        return {
            pageLoaded: false,
            sketchUpBrowser: (typeof sketchup !== "undefined"),
            sketchUpToolData: null,
            sectionCollapsed: {},
            sectionReload: {},
            data: null,
            actions: null,
            fields: null,
            showCreateButton: false,
            showDocumentCardFooter: false,
            showProjectCardFooter: false,
            showElementFooter: false,
            showRecordDialog: false,
            recordDialogParameters: {
                type: null,
                title: null,
                api: null,
                fields: null,
                data: null,
                ok_event: null,
                languages: this.languages,
                userLanguage: this.userLanguage,
            },
        }
    },
    watch: {
        $route() {
            this.fetchData(parseInt(this.$route.params.id));
        }
    },
    created() {
        if (this.sketchUpBrowser) {
            window.vm.Tool = this;
            sketchup.getToolData();
        }
        this.fetchData(parseInt(this.$route.params.id));
    },
    methods: {
        checkLayout() {
            this.showCreateButton = false;
            this.showDocumentCardFooter = false;
            this.showProjectCardFooter = false;
            this.showElementFooter = false;
            this.actions.manageActions.forEach(action => {
                if (this.sketchUpBrowser || !action.sketchup) {
                    if (action.placement === 'DOCUMENTHEADERRIGHT') {
                        this.showCreateButton = true;
                    }
                    if (action.placement === 'DOCUMENTCARDFOOTERLEFT' || action.placement === 'DOCUMENTCARDFOOTERRIGHT') {
                        this.showDocumentCardFooter = true;
                    }
                    if (action.placement === 'PROJECTCARDFOOTERLEFT' || action.placement === 'PROJECTCARDFOOTERRIGHT') {
                        this.showProjectCardFooter = true;
                    }
                    if (action.placement === 'ELEMENTFOOTERLEFT' || action.placement === 'ELEMENTFOOTERRIGHT') {
                        this.showElementFooter = true;
                    }
                }
            });
        },
        createContact(section) {
            this.recordDialogParameters.type = "create";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Contact", 1);
            this.recordDialogParameters.api = '/work_plans/phases/' + section.id + '/contact';
            this.recordDialogParameters.fields = this.fields.contactFields;
            this.recordDialogParameters.data = null;
            this.recordDialogParameters.ok_event = 'success-update-section';
            this.showRecordDialog = true;
        },
        createOrder() {
            this.recordDialogParameters.type = "create";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Order", 1);
            this.recordDialogParameters.api = '/orders';
            this.recordDialogParameters.fields = this.fields.orderFields;
            this.recordDialogParameters.data = null;
            this.recordDialogParameters.ok_event = 'success-create-order';
            this.showRecordDialog = true;
        },
        createRecipient(section) {
            this.recordDialogParameters.type = "create";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Delivery Address", 1);
            this.recordDialogParameters.api = '/work_plans/phases/' + section.id + '/contact';
            this.recordDialogParameters.fields = this.fields.recipientFields;
            this.recordDialogParameters.data = null;
            this.recordDialogParameters.ok_event = 'success-update-section';
            this.showRecordDialog = true;
        },
        createReminder(section) {
            this.recordDialogParameters.type = "create";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Reminder", 1);
            this.recordDialogParameters.api = '/work_plans/phases/' + section.id + '/reminder';
            this.recordDialogParameters.fields = this.fields.reminderFields;
            this.recordDialogParameters.data = {
                reference_date: section.calculated_delivery_date,
            };
            this.recordDialogParameters.ok_event = 'success-update-section';
            this.showRecordDialog = true;
        },
        deleteContact(section) {
            this.recordDialogParameters.type = "delete";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Contact", 1);
            this.recordDialogParameters.api = '/work_plans/phases/' + section.id + '/contact';
            this.recordDialogParameters.fields = this.fields.contactFields;
            this.recordDialogParameters.data = section.contact_id;
            this.recordDialogParameters.ok_event = 'success-update-section';
            this.showRecordDialog = true;
        },
        deleteRecipient(section) {
            this.recordDialogParameters.type = "delete";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Delivery Address", 1);
            this.recordDialogParameters.api = '/work_plans/phases/' + section.id + '/contact';
            this.recordDialogParameters.fields = this.fields.recipientFields;
            this.recordDialogParameters.data = section.recipient_id;
            this.recordDialogParameters.ok_event = 'success-update-section';
            this.showRecordDialog = true;
        },
        deleteReminder(section) {
            this.recordDialogParameters.type = "delete";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Reminder", 1);
            this.recordDialogParameters.api = '/work_plans/phases/' + section.id + '/reminder';
            this.recordDialogParameters.fields = this.fields.reminderFields;
            this.recordDialogParameters.data = section.reminders[0];
            this.recordDialogParameters.ok_event = 'success-update-section';
            this.showRecordDialog = true;
        },
        documentSelectInput(data) {
            if (data['id'] !== parseInt(this.$route.params.id)) {
                this.$router.push({params: {id: data['id']}});
            }
        },
        fetchData(id) {
            this.state.loading = true;
            this.pageLoaded = false;
            this.$http.get('/work_plans/manage/' + id, {}).then((res) => {
                this.setData(res.data);
                this.pageLoaded = !!res.data.data;
                this.state.loading = false;
            }).catch((error) => {
                console.log("WorkPlan:fetchData():error:", error);
            });
        },
        getToolDataCallback(data) {
            this.sketchUpToolData = data;
        },
        locateRecipient(section) {
            window.open(section.google_maps_url, 'google_maps');
        },
        selectView(option) {
            this.$router.push({name: option.routename, params: {id: this.data.id}});
        },
        setData(data) {
            this.data = data.data;
            this.data.sections.forEach(section => {
                this.sectionCollapsed = {
                    ...this.sectionCollapsed,
                    [section.id]: !section.work_plan_active
                }
                this.sectionReload = {
                    ...this.sectionReload,
                    [section.id]: 0
                }
            })
            this.actions = data.actions;
            this.fields = data.fields;
            this.checkLayout();
        },
        setToolDataCallback(data) {
            this.sketchUpToolData = data;
            this.state.loading = false;
        },
        successCreateOrder(data) {
            this.$router.push({name: 'orders', params: {id: data.data.id}});
        },
        toggleSection(section) {
            this.sectionCollapsed[section.id] = !this.sectionCollapsed[section.id];
        },
        toggleSketchUpOrder() {
            this.state.loading = true;
            if (this.sketchUpToolData.order_id === this.data.id) {
                sketchup.setToolData(this.data.project_id.id, 0, 0);
            } else {
                sketchup.setToolData(this.data.project_id.id, this.data.id, 0);
            }
        },
        toggleSketchUpPhase(section) {
            this.state.loading = true;
            if (this.sketchUpToolData.phase_id === section.id) {
                sketchup.setToolData(section.project_id, section.order_id, 0);
            } else {
                sketchup.setToolData(section.project_id, section.order_id, section.id);
            }
        },
        toggleSketchUpProject() {
            this.state.loading = true;
            if (this.sketchUpToolData.project_id === this.data.project_id.id) {
                sketchup.setToolData(0, 0, 0);
            } else {
                sketchup.setToolData(this.data.project_id.id, 0, 0);
            }
        },
        updateComment(data) {
            if (data.work_plan_active && this.fields.commentField.editinform) {
                this.recordDialogParameters.type = "update";
                this.recordDialogParameters.title = this.fields.commentField.label;
                this.recordDialogParameters.api = '/work_plans/phases';
                this.recordDialogParameters.fields = [this.fields.commentField];
                this.recordDialogParameters.data = data;
                this.recordDialogParameters.ok_event = 'success-update-section';
                this.showRecordDialog = true;
            }
        },
        updateContact(section) {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Contact", 1);
            this.recordDialogParameters.api = '/work_plans/phases/' + section.id + '/contact';
            this.recordDialogParameters.fields = this.fields.contactFields;
            this.recordDialogParameters.data = section.contact_id;
            this.recordDialogParameters.ok_event = 'success-update-section';
            this.showRecordDialog = true;
        },
        updateCustomer() {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.data.project_id.customer_id.label;
            this.recordDialogParameters.api = '/contacts';
            this.recordDialogParameters.fields = this.fields.customerFields;
            this.recordDialogParameters.data = this.data.project_id.customer_id;
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        updateOrder() {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.data.label;
            this.recordDialogParameters.api = '/orders';
            this.recordDialogParameters.fields = this.fields.orderFields;
            this.recordDialogParameters.data = this.data;
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        updateProject() {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.data.project_id.label;
            this.recordDialogParameters.api = '/projects';
            this.recordDialogParameters.fields = this.fields.projectFields;
            this.recordDialogParameters.data = this.data.project_id;
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        updateRecipient(section) {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Delivery Address", 1);
            this.recordDialogParameters.api = '/work_plans/phases/' + section.id + '/contact';
            this.recordDialogParameters.fields = this.fields.recipientFields;
            this.recordDialogParameters.data = section.recipient_id;
            this.recordDialogParameters.ok_event = 'success-update-section';
            this.showRecordDialog = true;
        },
        updateReminder(section) {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Reminder", 1);
            this.recordDialogParameters.api = '/work_plans/phases/' + section.id + '/reminder';
            this.recordDialogParameters.fields = this.fields.reminderFields;
            this.recordDialogParameters.data = section.reminders[0];
            this.recordDialogParameters.ok_event = 'success-update-section';
            this.showRecordDialog = true;
        },
        sectionUpdated(data) {
            let sectionIdx = this.data.sections.findIndex(section => section.id === data.data.id);
            this.data.sections[sectionIdx] = data.data;
            this.sectionReload[data.data.id]++;
            this.state.loading = false;
        },
    }
}
</script>

<style scoped>
.phase-header-table {
    width: 100%;
}

.phase-header-table tr td {
    margin: 0;
    padding: 5px 5px;
    vertical-align: center;
}

.phase-header {
    cursor: default;
    padding: 0 15px;
}

.phase-body {
    padding: 10px 10px;
}
</style>
